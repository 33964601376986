@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  button {
    @apply focus:outline-none;
  }
  a {
    @apply outline-none;
  }
}

body {
  margin: 0;
}

li {
  list-style-type: none;
}

.secondary-background {
  background: #FA9B1E !important;
}

h1 {
  display: inline-block;
  -webkit-transform: scale(1.2, 1); /* Safari and Chrome */
  -moz-transform: scale(1.2, 1); /* Firefox */
  -ms-transform: scale(1.2, 1); /* IE 9 */
  -o-transform: scale(1.2, 1); /* Opera */
  transform: scale(1.2, 1);
}

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.map {
  height: 100%;
  width: 100%;
}

#scrollable::-webkit-scrollbar {
  padding: 4px;
  width: 14px;
  border-radius: 4px;
}

#scrollable::-webkit-scrollbar-track {
  margin: 4px;
  background-color: transparent;
}

#scrollable::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 16px;
  border: solid 4px white;
}

.app-bar {
  position: fixed;
  left: 0;
  width: 100%;
}

.tool-bar {
  padding-left: 90px !important;
}

.bar-title {
  flex-grow: 1;
}

.float-right {
  float: right;
}

.center {
  text-align: center;
}


.root {
  padding: 0;
  margin: 0;
}

.center {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: min-content;
  height: min-content;
}

.centerer {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: min-content;
  height: min-content;
}

#root {
  padding: 0;
  margin: 0;
}

.cover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.nowrap {
  white-space: nowrap;
  overflow: hidden;
}

.flex-grow {
  flex-grow: 1;
}

.error {
  color: red;
}

.warning {
  color: yellow;
}

.dialog {
  z-index: 1251;
}

.dialog-background {
  z-index: 1250;
  background: black;
  opacity: 0.5;
}

.visibility-fade {
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms 0ms ease, visibility 300ms 0ms ease;
}

.visible-fade-show {
  opacity: 0.5;
  visibility: visible;
  transition: opacity 300ms 0ms ease, visibility 0ms 0ms ease;
}

body {
  min-height: 100vh;
  background-color: whitesmoke;
}

.expanded-header {
  flex-basis: 33%;
}

#scrollable::-webkit-scrollbar {
  padding: 4px;
  width: 14px;
  border-radius: 4px;
}

#scrollable::-webkit-scrollbar-track {
  margin: 4px;
  background-color: transparent;
}

#scrollable::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 16px;
  border: solid 4px white;
}

#menu-Cement {
  z-index: 1400 !important;
}

.MuiSelect-selectMenu {
  text-align: left;
}

.hover-icon svg {
  color: gray;
  visibility: hidden;
}

.hover-icon:hover svg {
  visibility: visible;
}
