.login-body {
  height: min-content;
  width: min-content;
  background-color: white;
  border-radius: 16px;
  box-shadow: black 0 5px 10px 0;
  margin: auto;
}

.login-divider {
  display: none;
  background-color: #d3d3d3;
  width: 1px;
  margin: 0 8px;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.shrink {
  height: min-content;
  width: min-content;
}

.login-progress-container {
  position: relative;
  display: inline;
  top: 6px;
  right: 8px;
}

.login-progress {
  width: 22px !important;
  height: 22px !important;
}

.login-form-spacer {
  display: none;
  height: 16px;
}

.login-field-container {
  padding: 0 16px 0;
}

.login-button-container {
  float: right;
  padding-right: 16px;
}

.login-logo {
  padding: 8px;
  width: min-content;
  height: min-content;
  margin: auto;
}

.login-info {
  min-width: 250px;
}

.login-form {
  min-width: 300px;
  min-height: 100px;
}

.login-footer {
  padding: 16px
}

@media (min-width: 800px) {

  .login-form-spacer {
    display: inherit;
  }
  
  .login-body {
    position: relative;
    display: flex;
  }

  .login-footer {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .login-button-container {
    padding-top: 16px;
  }
  
  .login-divider {
    display: inherit;
  }

  .login-body {
    padding: 16px 0;
  }
}