.archive-list {
  padding: 5em 1em 1em 4.5em !important;
}

.archive-signature-x {
  float: left;
  position: relative;
  top: 40px
}

.archive-ticket-body {
  padding: 5em 1em 1em 22em;
}


.side-bar-tool-bar {
  min-height: 56px !important;
  padding: 0 !important;
}

.side-bar-list {
  padding-left: 3.5em !important;
}

.side-bar {
  position: fixed;
  left: 1em;
  top: 0;
  bottom: 0;
  width: 20em;
  overflow: hidden;
  border-radius: 0 1em 1em 0 !important;
  z-index: 1100;
}
