.dispatch-toolbar {
  border-radius: 0 !important;
  position: fixed;
  top: 0;
  left: 1em;
  bottom: 0;
  width: 7em;
  background-color: #FA9B1E !important;
}

.dispatch-toolbar-list {
  margin-left: 3.75em;
}

.dispatch-toolbar-list-bottom {
  position: absolute;
  bottom: 0;
  margin-left: 3.75em;
}

.dispatch-map-view {
  padding-left: 7em;
}

.tool-window-date-body {
  position: fixed;
  z-index: 200;
  width: fit-content;
  height: fit-content;
  top: 1em;
  left: 7.5em;
}

.tool-window-date-grid {
  padding: 1em;
  max-width: 240px;
}

.tool-window-layers-text {
  min-width: 16em;
}


.tool-window-date-footer {
  background-color: dodgerblue;
  color: white;
  padding: 0.5em;
  max-width: 240px;
}

.tool-window-layers-body {
  position: fixed;
  z-index: 9001;
  width: fit-content;
  height: fit-content;
  top: 3em;
  left: 9em;
}

.tool-window-layers-washout {
  position: absolute;
  z-index: 200;
  background-color: lightgrey;
  opacity: 0.9;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.tool-window-layers-grid {
  padding: 0 1em 0 0;
  max-width: 400px;
}


.tool-window-layers-footer {
  background-color: dodgerblue;
  color: white;
  padding: 0.5em;
  min-width: 15em;
}

.tool-window-assignment-body {
  position: fixed;
  z-index: 200;
  width: fit-content;
  height: fit-content;
  bottom: 1em;
  left: 8em;
}

.tool-window-assignment-footer {
  background-color: dodgerblue;
  color: white;
  min-width: 45em;
  width: 100%;
}

.tool-window-assignment-view {
  background-color: whitesmoke;
  height: 30em;
  width: 100%;
  max-width: 45em;
  padding-bottom: 1em;
}

.tool-window-assignment-header {
  padding: 0.7em 2em !important;
  background-color: lightgrey;
}

.tool-window-assignment-list {
   padding: 1em !important;
   height: 25.3em;
   overflow-y: auto;
   overflow-x: hidden;
 }

.tool-window-assignment-assign button {
  opacity: 0 !important;
}

.tool-window-assignment-assign:hover button {
  opacity: 1 !important;
}

.tool-window-assignment-assign-button {
  flex: none !important;
  display: block !important;
  position: absolute !important;
  top: 0.5em;
  right: 0.5em;
}

.tool-window-assignment-open-button {
  flex: none !important;
  display: block !important;
  position: absolute !important;
  bottom: 0.5em;
  right: 0.5em;
}

.tool-window-assignment-flag {
  flex: none !important;
  display: block !important;
  position: absolute !important;
  bottom: 0.5em;
  right: 0.5em;
  opacity: 1 !important;
}

.tool-window-assignment-flag svg {
  color: #FA9B1E
}

.customer-select {
  min-width: 30em;
  max-width: 40em;
}

.customer-select-search {
  font-size: 1.5em !important;
  padding: 0.3em 1em 0.1em 1em;
}

.customer-select-list {
  height: 15em;
  overflow-x: auto;
}

.customer-select-actions {
  background-color: lightgrey;
}


.customer-select-new {
  color: dodgerblue;
}

.add-assignment-fab {
  position: relative;
  left: 28em;
}

.address-select {
  position: relative;
  top: 3.5em;
}

.address-suggestions {
  padding: 0;
  margin: 0.5em 0 0;
  height: 10em;
  overflow-x: auto;
}

.address-select-field {
  padding: 1em;
}

.address-suggestions-paper {
  position: absolute;
  z-index: 300;
  width: fit-content;
  max-height: 200px;
  overflow: auto;
}
